import React, { useEffect } from "react";
import freezerbox from "../images/freezerBox.jpg";
import { Link } from "react-router-dom";
import logo from "../images/Rapid Ambulance.png";
import footerLogo from "../images/Swift Care White.png";
const FreezerBox = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const centerLastLineStyle = {
    textAlign: "justify", // Justify text
    textAlignLast: "center", // Center the last line
    color: "#8a8a8a",
  };

  return (
    <>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <Link to="/">
            {" "}
            <div>
              <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
            </div>
          </Link>
        </div>
      </nav> */}

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-5">
            <img
              src={freezerbox}
              className="w-100 my-5"
              alt="Ventilator services"
            />
          </div>
          <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
            <h5>We offer Freezer Box services</h5>
            <h3>Freezer Box Ambulance Services</h3>
            <p>
              Facilitating freezer box services for the dignified and respectful
              transportation of deceased individuals with care.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={centerLastLineStyle}>
        <p>
          In Hyderabad, Freezer Box services play a pivotal role in providing
          dignified and respectful handling of deceased individuals during times
          of mourning. These specialized services, often offered by reputable
          providers like Rapid Ambulances, ensure the proper preservation and
          transportation of deceased individuals until their final arrangements
          can be made. With Hyderabad's diverse population and cultural
          practices, Freezer Box services serve as a crucial aspect of funeral
          arrangements, offering families the peace of mind that their loved
          ones are handled with care and respect during the grieving process.
        </p>

        <p className="pt-5">
          Moreover, the availability of Freezer Box services reflects
          Hyderabad's commitment to upholding cultural and religious
          sensitivities surrounding death and burial rites. By offering options
          for dignified storage and transportation of deceased individuals,
          these services cater to the diverse needs of the community, regardless
          of religious or cultural backgrounds. In a city known for its rich
          tapestry of traditions and beliefs, Freezer Box services contribute to
          the fabric of communal support, ensuring that families can navigate
          the complexities of loss with compassion and understanding.
        </p>
        <p className="pt-5 pb-5">
          Furthermore, the presence of Freezer Box services underscores
          Hyderabad's forward-thinking approach to addressing the practical
          challenges associated with end-of-life care. By providing a seamless
          and efficient process for preserving and transporting deceased
          individuals, these services alleviate logistical burdens for grieving
          families, allowing them to focus on honoring the memory of their loved
          ones. In times of sorrow and uncertainty, the availability of Freezer
          Box services offered by reputable providers like Rapid Ambulances
          serves as a beacon of compassion and support, embodying Hyderabad's
          commitment to dignity, respect, and care for all members of the
          community, even in the face of life's most difficult moments.
        </p>
      </div>

      <div className="text-center">
        <Link to="/">
          {" "}
          <button
            style={{ width: "fit-content" }}
            className="my-3 estimate_button mr-2 p-3 mb-5"
          >
            <i class="ri-arrow-left-line"></i> Back To Home
          </button>
        </Link>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Our commitment to expert precision shines through, guiding
                    you from moments of emergency to a realm of dedicated care.
                    Our ambulance services embody the essence of Rapidness,
                    ensuring that with just a call, essential care is at your
                    fingertips. Recognizing that every second holds profound
                    significance, we unleash the power of urgent, professional
                    care, understanding that your well-being hinges on rapid
                    intervention.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreezerBox;
