import React, { useEffect } from "react";
import ventilation from "../images/ventilator.jpg";
import logo from "../images/Rapid Ambulance.png";
import { Link } from "react-router-dom";
import footerLogo from "../images/Swift Care White.png";

const Ventilator = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const centerLastLineStyle = {
    textAlign: "justify", // Justify text
    textAlignLast: "center", // Center the last line
    color: "#8a8a8a",
  };
  return (
    <>

<div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      
      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <Link to="/">
            {" "}
            <div>
              <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
            </div>
          </Link>
        </div>
      </nav> */}

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-5">
            <img
              src={ventilation}
              className="w-100 my-5"
              alt="Ventilator services"
            />
          </div>
          <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
            <h5>We offer ICU Ventilator services</h5>
            <h3>ICU Ventilator Ambulance Services</h3>
            <p>
              Having dependable and prompt ICU Ventilator ambulance services is
              essential during medical emergencies when every second counts
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={centerLastLineStyle}>
        <p>
          In Hyderabad, Rapid Ambulances are at the forefront of critical care
          transportation, particularly with the integration of ventilator
          ambulances into emergency medical services. These specialized
          vehicles, bearing the emblem of Rapid Ambulances, are equipped with
          cutting-edge ventilatory support systems, ensuring swift and efficient
          transport for patients in need of advanced respiratory assistance.
          Strategically stationed across the city, these ambulances stand as
          beacons of hope, ready to respond promptly to emergency calls and
          provide life-saving respiratory care en route to medical facilities.
        </p>

        <p className="pt-5">
          The presence of Rapid Ambulances underscores Hyderabad's commitment to
          bolstering healthcare infrastructure and emergency response
          capabilities. By offering specialized respiratory support during
          transit, Rapid Ambulances play a crucial role in improving patient
          outcomes and reducing mortality rates associated with respiratory
          emergencies. Moreover, the name "Rapid Ambulances" epitomizes the
          city's dedication to delivering timely and effective medical
          intervention, reflecting Hyderabad's proactive stance in meeting the
          evolving healthcare needs of its populace. Through the deployment of
          these advanced resources, Rapid Ambulances exemplify Hyderabad's
          unwavering commitment to ensuring the well-being and safety of its
          residents, setting a precedent for excellence in critical care
          transportation.
        </p>
        <p className="pt-5 pb-5">
          In addition to their life-saving capabilities, Rapid Ambulances in
          Hyderabad serve as symbols of reassurance and trust for the local
          community. With their distinctive branding and unwavering commitment
          to swift medical intervention, these ambulances instill confidence
          among residents facing medical emergencies. The sight of a Rapid
          Ambulance racing through the city streets represents a beacon of hope,
          signaling that help is on the way during times of distress. Beyond
          their role in emergency response, Rapid Ambulances foster a sense of
          unity and solidarity, embodying the collective effort to prioritize
          the health and safety of Hyderabad's residents. As integral components
          of the city's healthcare ecosystem, Rapid Ambulances not only save
          lives but also serve as a testament to Hyderabad's resilience and
          dedication to ensuring the well-being of its community members.
        </p>
      </div>

      <div className="text-center">
        <Link to="/">
          {" "}
          <button
            style={{ width: "fit-content" }}
            className="my-3 estimate_button mr-2 p-3 mb-5"
          >
            <i class="ri-arrow-left-line"></i> Back To Home
          </button>
        </Link>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Our commitment to expert precision shines through, guiding
                    you from moments of emergency to a realm of dedicated care.
                    Our ambulance services embody the essence of Rapidness,
                    ensuring that with just a call, essential care is at your
                    fingertips. Recognizing that every second holds profound
                    significance, we unleash the power of urgent, professional
                    care, understanding that your well-being hinges on rapid
                    intervention.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default Ventilator;
