import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import footerLogo from "./images/Swift Care White.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="about_bg">{/* <h1>About</h1> */}</div>
      <div className="container About_page p-3">
        <div style={{ marginTop: "30px", color: "#ff3131" }}>
          <h4>OUR MISSION:</h4>
        </div>
        <p>
          At Rapid Ambulance, our mission is to provide swift, reliable, and
          compassionate emergency medical services to the residents or visitors
          of Hyderabad. Our commitment lies in delivering timely aid during
          critical moments, ensuring every individual receives the highest
          standard of care with professionalism and empathy.
        </p>

        <p>
          Driven by our dedication to saving lives and promoting community
          health, we strive to be the premier ambulance service in Hyderabad,
          recognized for our efficiency, expertise, and unwavering focus on
          patient well-being.
        </p>

        <p>
          Through continuous innovation, training, and investment in
          state-of-the-art medical technology, we aim to exceed expectations,
          setting new benchmarks for excellence in emergency medical response.
        </p>

        <p>
          <b>
            At Rapid Ambulance, we stand ready 24/7, ready to serve, ready to
            care."
          </b>
        </p>

        <div style={{ marginTop: "30px", color: "#ff3131" }}>
          <h4>Ventilator Ambulance:</h4>
        </div>

        <p>
          Welcome to Rapid Ambulance, your trusted provider of ICU Ventilator
          Ambulance Services in Hyderabad. We understand the critical nature of
          medical emergencies, and our dedicated team is committed to ensuring
          prompt and efficient response to your needs.
        </p>

        <p>
          Our Ventilator Ambulance Service in Hyderabad is equipped with
          state-of-the-art ICU facilities, including advanced ventilator
          systems, to provide life-saving care during transit. Whether you
          require inter-hospital transfers or emergency transportation to
          medical facilities, our ambulances are staffed with skilled medical
          professionals who prioritize your safety and well-being.
        </p>

        <p>
          At Rapid Ambulance, we prioritize timeliness without compromising on
          the quality of care. Our fleet of ambulances is strategically
          stationed across Hyderabad, ready to dispatch at a moment's notice. We
          adhere to stringent medical protocols to deliver optimal care to
          patients in critical conditions.
        </p>
        <p>
          With Rapid Ambulance, you can have peace of mind knowing that you or
          your loved ones are in capable hands during medical emergencies. Our
          commitment to excellence drives us to continually upgrade our services
          and stay at the forefront of emergency medical care in Hyderabad.
        </p>
        <p>
          Contact Rapid Ambulance today for reliable ICU Ventilator Ambulance
          Services in Hyderabad, and let us be your partner in ensuring timely
          and efficient medical assistance when it matters most.
        </p>
      </div>

      <div className="footerSection ">
        <div className="container">
          <div className="row  firstFooterContainer ">
            <div className="firstCol  col-12">
              <div>
                <img
                  src={footerLogo}
                  className="pabTopLogo"
                  id="bottomPabLogo"
                  alt=""
                />
                <p className="footerText">
                  Our commitment to expert precision shines through, guiding you
                  from moments of emergency to a realm of dedicated care. Our
                  ambulance services embody the essence of Rapidness, ensuring
                  that with just a call, essential care is at your fingertips.
                  Recognizing that every second holds profound significance, we
                  unleash the power of urgent, professional care, understanding
                  that your well-being hinges on rapid intervention.
                </p>
              </div>
            </div>

            <div className="thirdCol  col-12 ">
              <h4 className="text-center">Services </h4>

              <div className="d-flex flex-wrap justify-content-around">
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/BLS-ambulance">
                    <span>Basic Life Support</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/ventilator-ambulance">
                    <span>Ventilator Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Air-ambulance">
                    <span>Air Ambulance Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/freezer-box">
                    <span> Freezer Box Services</span>
                  </Link>
                </div>
                <div className="text-center" style={{ width: "200px" }}>
                  <Link to="/Embalming">
                    <span>Embalming</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="thick-horizontal-line mx-2 "
            style={{ height: "1px", backgroundColor: "white" }}
          ></div>

          <div className="container secondFooterContainer row ">
            <div className="text-white col-md-6  col-12 align-self-center mt-4">
              <i className="fa-solid fa-copyright"></i> Copyright RAPID
              Ambulance. All rights reserved.
            </div>

            <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
              <i className="fa-brands fa-linkedin mx-2 logos" id="linkedIn"></i>
              <i
                className="fa-brands fa-instagram mx-2 logos"
                id="instagram"
              ></i>
              <i className="fa-brands fa-facebook mx-2 logos" id="facebook"></i>
              <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
