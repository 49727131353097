import React, { useEffect } from "react";
import blsoxygen from "../images/oxygenBLS.jpg";
import { Link } from "react-router-dom";
import logo from "../images/Rapid Ambulance.png";
import footerLogo from "../images/Swift Care White.png";

const BLSamulance = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const centerLastLineStyle = {
    textAlign: "justify", // Justify text
    textAlignLast: "center", // Center the last line
    color: "#8a8a8a",
  };

  return (
    <>

<div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      
      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <Link to="/">
            {" "}
            <div>
              <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
            </div>
          </Link>
        </div>
      </nav> */}

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-5">
            <img
              src={blsoxygen}
              className="w-100 my-5"
              alt="Ventilator services"
            />
          </div>
          <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
            <h5>We offer BLS oxygen Ambulance</h5>
            <h3>BLS Oxygen Ambulance Services</h3>
            <p>
              BLS ambulance outfitted with essential medical equipment and
              oxygen supply for emergency patient care and transport
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={centerLastLineStyle}>
        <p>
          In Hyderabad, BLS (Oxygen) Ambulances stand as vital pillars of
          emergency medical services, providing crucial oxygen support to
          patients in need. These specialized vehicles, bearing the emblem of
          BLS (Basic Life Support) Ambulances, are equipped with essential
          medical equipment, including oxygen delivery systems, to ensure prompt
          and effective care during transit. Strategically stationed across the
          city, these ambulances serve as lifelines for individuals experiencing
          respiratory distress or other medical emergencies requiring oxygen
          therapy.
        </p>

        <p className="pt-5">
          The presence of BLS (Oxygen) Ambulances underscores Hyderabad's
          commitment to delivering immediate medical intervention and basic life
          support to those in critical need. With their ability to provide
          oxygen therapy en route to medical facilities, these ambulances play a
          pivotal role in stabilizing patients and improving their chances of
          recovery. Moreover, the name "BLS (Oxygen) Ambulances" symbolizes
          Hyderabad's dedication to prioritizing the most fundamental aspects of
          emergency medical care, ensuring that even the most basic life-saving
          measures are readily available to its residents.
        </p>
        <p className="pt-5 pb-5">
          Beyond their life-saving capabilities, BLS (Oxygen) Ambulances serve
          as symbols of compassion and solidarity within the local community.
          The sight of these ambulances rushing to the aid of those in need
          instills a sense of reassurance and confidence among Hyderabad's
          residents, knowing that help is swiftly on its way during moments of
          crisis. As integral components of the city's emergency response
          infrastructure, BLS (Oxygen) Ambulances embody Hyderabad's unwavering
          commitment to providing timely and effective medical assistance,
          safeguarding the health and well-being of its populace.
        </p>
      </div>

      <div className="text-center">
        <Link to="/">
          {" "}
          <button
            style={{ width: "fit-content" }}
            className="my-3 estimate_button mr-2 p-3 mb-5"
          >
            <i class="ri-arrow-left-line"></i> Back To Home
          </button>
        </Link>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Our commitment to expert precision shines through, guiding
                    you from moments of emergency to a realm of dedicated care.
                    Our ambulance services embody the essence of Rapidness,
                    ensuring that with just a call, essential care is at your
                    fingertips. Recognizing that every second holds profound
                    significance, we unleash the power of urgent, professional
                    care, understanding that your well-being hinges on rapid
                    intervention.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default BLSamulance;
