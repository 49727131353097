import React from "react";
import { BrowserRouter, routes, route, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Ventilator from "./Components/Ventilator";
import BLSamulance from "./Components/BLSamulance";
import AirAmbulance from "./Components/AirAmbulance";
import FreezerBox from "./Components/FreezerBox";
import Embalming from "./Components/Embalming";
import Navbar from "./Navbar/Navbar";
import Contact from "./Contact";
import About from "./About";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        {/* <Nav /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/ventilator-ambulance" element={<Ventilator />} />
          <Route exact path="/BLS-ambulance" element={<BLSamulance />} />
          <Route exact path="/Air-ambulance" element={<AirAmbulance />} />
          <Route exact path="/freezer-box" element={<FreezerBox />} />
          <Route exact path="/Embalming" element={<Embalming />} />

          <Route exact path="/Navbar" element={<Navbar />} />

          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/about" element={<About/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
