import React, { useEffect } from "react";
import airAmbulance from "../images/airambulance.jpg";
import { Link } from "react-router-dom";
import logo from "../images/Rapid Ambulance.png";
import footerLogo from "../images/Swift Care White.png";

const AirAmbulance = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const centerLastLineStyle = {
    textAlign: "justify", // Justify text
    textAlignLast: "center", // Center the last line
    color: "#8a8a8a",
  };

  return (
    <>


<div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i>+91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>

      
      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <Link to="/">
            {" "}
            <div>
              <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
            </div>
          </Link>
        </div>
      </nav> */}

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-5">
            <img
              src={airAmbulance}
              className="w-100 my-5"
              alt="Ventilator services"
            />
          </div>
          <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
            <h5>We offer Aerial Ambulance services</h5>
            <h3>AIR Ambulance Services</h3>
            <p>
              Air ambulance service providing essential oxygen support for
              critical care patients during aerial medical transport
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={centerLastLineStyle}>
        <p>
          In Hyderabad, AIR Ambulances operated under the banner of Rapid
          Ambulances soar above the skyline, embodying a new dimension of
          emergency medical services. These specialized aircraft are equipped
          with cutting-edge medical equipment, including ventilators, enabling
          them to provide critical care and life-saving interventions while
          traversing the skies. With the bustling urban landscape of Hyderabad
          and its surrounding regions, AIR Ambulances play a pivotal role in
          bridging the gap between remote areas and advanced medical facilities,
          ensuring timely access to specialized care for patients in need.
        </p>

        <p className="pt-5">
          The introduction of AIR Ambulances by Rapid Ambulances represents a
          paradigm shift in emergency medical response, leveraging the speed and
          versatility of aviation to deliver swift and efficient medical
          evacuation services. Whether responding to medical emergencies in
          urban centers or reaching remote locations inaccessible by traditional
          land-based ambulances, AIR Ambulances under the Rapid Ambulances
          banner are equipped to deliver urgent care with unparalleled speed and
          precision.
        </p>
        <p className="pt-5 pb-5">
          Furthermore, the presence of AIR Ambulances underscores Hyderabad's
          commitment to innovation and excellence in healthcare delivery. By
          integrating aerial medical transport into its emergency response
          infrastructure, Rapid Ambulances exemplifies Hyderabad's proactive
          approach to meeting the evolving healthcare needs of its growing
          population. In times of crisis or medical emergencies, the sight of a
          Rapid Ambulance AIR Ambulance descending from the skies offers a
          beacon of hope and assurance, symbolizing the city's unwavering
          dedication to ensuring the well-being and safety of its residents.
        </p>
      </div>

      <div className="text-center">
        <Link to="/">
          {" "}
          <button
            style={{ width: "fit-content" }}
            className="my-3 estimate_button mr-2 p-3 mb-5"
          >
            <i class="ri-arrow-left-line"></i> Back To Home
          </button>
        </Link>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Our commitment to expert precision shines through, guiding
                    you from moments of emergency to a realm of dedicated care.
                    Our ambulance services embody the essence of Rapidness,
                    ensuring that with just a call, essential care is at your
                    fingertips. Recognizing that every second holds profound
                    significance, we unleash the power of urgent, professional
                    care, understanding that your well-being hinges on rapid
                    intervention.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirAmbulance;
