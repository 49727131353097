import React, { useEffect } from "react";
import emblam from "../images/embalming.jpg";
import { Link } from "react-router-dom";
import logo from "../images/Rapid Ambulance.png";
import footerLogo from "../images/Swift Care White.png";

const Embalming = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const centerLastLineStyle = {
    textAlign: "justify", // Justify text
    textAlignLast: "center", // Center the last line
    color: "#8a8a8a",
  };

  return (
    <>
      <div className="floating-icons">
        <a href="tel:+919030820612" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919030820612")}
            className="red_fixed_button"
          >
            <i className="fa-solid fa-phone-volume"></i> +91 9030820612
          </button>
        </a>
        <a href="https://wa.me/+919030820612" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="fa-brands fa-whatsapp "></i> Whatsapp
          </button>
        </a>
      </div>
      {/* <nav
        className="navbar navbar-expand-lg  fixed-top"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container align-items-center justify-content-center">
          <Link to="/">
            {" "}
            <div>
              <img src={logo} alt="logo" className="img-fluid pabTopLogo" />
            </div>
          </Link>
        </div>
      </nav> */}

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-md-5">
            <img
              src={emblam}
              className="w-100 my-5"
              alt="Ventilator services"
            />
          </div>
          <div className="col-md-6 services_tabs d-flex justify-content-center flex-column">
            <h5>We offer Embalming services</h5>
            <h3>Expert Embalming Services</h3>
            <p>
              The procedure of embalming a deceased person is careful and
              courteous, guaranteeing that the body will be preserved in a
              respectable manner until the burial or viewing
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={centerLastLineStyle}>
        <p>
          Embalming holds a significant role in funeral practices, offering a
          dignified and respectful means of preserving the deceased's body. In
          Hyderabad, the practice of embalming is conducted with meticulous care
          and attention to detail, reflecting the cultural reverence for the
          departed. Skilled embalmers utilize specialized techniques and
          embalming fluids to temporarily delay the body's natural
          decomposition, allowing loved ones more time to pay their final
          respects. This process not only preserves the physical integrity of
          the deceased but also provides a sense of closure and comfort to
          grieving families as they bid farewell to their loved ones.
        </p>

        <p className="pt-5">
          Furthermore, the practice of embalming in Hyderabad is deeply
          intertwined with religious and cultural traditions, honoring the
          deceased and facilitating funeral rites with reverence and respect.
          Embalming plays a crucial role in Hindu, Muslim, and Christian funeral
          customs, ensuring that the deceased is prepared for burial or
          cremation according to their religious beliefs and customs. Families
          entrust skilled embalmers to handle this delicate process with
          sensitivity and compassion, recognizing the importance of upholding
          cultural traditions while honoring the memory of the departed.
        </p>
        <p className="pt-5 pb-5">
          Moreover, beyond its cultural significance, embalming in Hyderabad
          serves practical purposes in cases where there is a delay in funeral
          arrangements or when the deceased is to be transported over long
          distances. By preserving the body, embalming allows for safe and
          hygienic transportation, enabling families to conduct funeral
          ceremonies at their preferred time and location. This aspect of
          embalming underscores its role not only in honoring cultural
          traditions but also in facilitating practical considerations during
          times of loss, providing families with the flexibility and peace of
          mind they need to navigate the funeral process with dignity and grace.
        </p>
      </div>

      <div className="text-center">
        <Link to="/">
          {" "}
          <button
            style={{ width: "fit-content" }}
            className="my-3 estimate_button mr-2 p-3 mb-5"
          >
            <i class="ri-arrow-left-line"></i> Back To Home
          </button>
        </Link>
      </div>

      <div data-aos="fade-in" data-aos-duration="1500">
        <div className="footerSection ">
          <div className="container">
            <div className="row  firstFooterContainer ">
              <div className="firstCol  col-12">
                <div>
                  <img
                    src={footerLogo}
                    className="pabTopLogo"
                    id="bottomPabLogo"
                    alt=""
                  />
                  <p className="footerText">
                    Our commitment to expert precision shines through, guiding
                    you from moments of emergency to a realm of dedicated care.
                    Our ambulance services embody the essence of Rapidness,
                    ensuring that with just a call, essential care is at your
                    fingertips. Recognizing that every second holds profound
                    significance, we unleash the power of urgent, professional
                    care, understanding that your well-being hinges on rapid
                    intervention.
                  </p>
                </div>
              </div>

              <div className="thirdCol  col-12 ">
                <h4 className="text-center">Services </h4>

                <div className="d-flex flex-wrap justify-content-around">
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/BLS-ambulance">
                      <span>Basic Life Support</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/ventilator-ambulance">
                      <span>Ventilator Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Air-ambulance">
                      <span>Air Ambulance Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/freezer-box">
                      <span> Freezer Box Services</span>
                    </Link>
                  </div>
                  <div className="text-center" style={{ width: "200px" }}>
                    <Link to="/Embalming">
                      <span>Embalming</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="thick-horizontal-line mx-2 "
              style={{ height: "1px", backgroundColor: "white" }}
            ></div>

            <div className="container secondFooterContainer row ">
              <div className="text-white col-md-6  col-12 align-self-center mt-4">
                <i className="fa-solid fa-copyright"></i> Copyright RAPID
                Ambulance. All rights reserved.
              </div>

              <div className=" col-md-2  d-flex flex-row col-12 mt-4 ">
                <i
                  className="fa-brands fa-linkedin mx-2 logos"
                  id="linkedIn"
                ></i>
                <i
                  className="fa-brands fa-instagram mx-2 logos"
                  id="instagram"
                ></i>
                <i
                  className="fa-brands fa-facebook mx-2 logos"
                  id="facebook"
                ></i>
                <i className="fa-brands fa-twitter mx-2 logos" id="twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Embalming;
